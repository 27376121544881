import { createSignal, Show } from "solid-js";
import { Box, ButtonGroup, Button, Stack, Popover, Typography } from "@suid/material";
import { AddIcon,PlaylistAddIcon,PlaylistRemoveIcon, faHeartCirclePlus, faHeartCircleMinus} from "../icons"

import Fa from '~/src/components/SolidFa'
import { getText } from '../SmallComponents/Translate'

const MainLeftButtons = () => {

    const [isSharing, setIsSharing] = createSignal(false);
    const [isFavorite, setIsFavorite] = createSignal(false);
    const handleToogleShare = () => {
        setIsSharing(isSharing() ? false : true)
    }
    const handleToogleFavorite = () => {
        setIsFavorite(isFavorite() ? false : true)
    }

    const [popoverText, setPopoverText] = createSignal("PopoverText is not defined");
    const openPopover = () => Boolean(anchorEl());
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);
    const handlePopoverOpen = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const txtAddFavourit = getText("default", "addfavourit")
    const txtRemoveFavourit = getText("default", "removefavourit")
    const txtRemoveTeamShare = getText("default", "removeteamshare")
    const txtShareWithTeam = getText("default", "addteamshare")

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        "& > *": {
                            m: 1,
                        },
                    }}
                >

                    <ButtonGroup variant="outlined" size="small" color="primary" aria-label="outlined button group">
                        <Show
                            fallback={
                                <Button startIcon={<Fa icon={faHeartCirclePlus} />}
                                    onMouseEnter={(event) => {
                                        setPopoverText(txtAddFavourit() as string);
                                        handlePopoverOpen(event);
                                    }}
                                    onMouseLeave={handlePopoverClose} onClick={handleToogleFavorite} >

                                </Button>
                            }
                            when={isFavorite()}

                        >
                            <Button startIcon={<Fa icon={faHeartCircleMinus} />}
                                onMouseEnter={(event) => {
                                    setPopoverText(txtRemoveFavourit() as string);
                                    handlePopoverOpen(event);
                                }}
                                onMouseLeave={handlePopoverClose}
                                onClick={handleToogleFavorite} >

                            </Button>

                        </Show>
                        <Show
                            fallback={
                                <Button startIcon={<PlaylistRemoveIcon />}
                                    onMouseEnter={(event) => {
                                        setPopoverText(txtRemoveTeamShare() as string);
                                        handlePopoverOpen(event);
                                    }}
                                    onMouseLeave={handlePopoverClose}
                                    onClick={handleToogleShare}>

                                </Button>
                            }
                            when={isSharing()}

                        >
                            <Button startIcon={<PlaylistAddIcon />} 
                             onMouseEnter={(event) => {
                                setPopoverText(txtShareWithTeam() as string);
                                handlePopoverOpen(event);
                            }}
                            onMouseLeave={handlePopoverClose}
                                onClick={handleToogleShare}>
                            </Button>

                        </Show>
                    </ButtonGroup>

                </Box>
            </Stack>
            <Popover
                id="mouse-over-popover" sx={{ pointerEvents: "none" }} open={openPopover()}
                anchorEl={anchorEl()}
                anchorOrigin={{ vertical: "bottom", horizontal: "left", }}
                transformOrigin={{ vertical: "top", horizontal: "left", }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ px: 2, py: 2, }} variant="body2" align="center" gutterBottom> {popoverText()}</Typography>
            </Popover>
        </>
    )
}



const WFMatchingButtons = () =>{

    const handleChipDelete = () => {
        console.info("Function is work in progress");
    };

    const txtAll = getText("default", "all")
    const txtMatches = getText("workflowpage", "matches")
    const txtNoMatches = getText("workflowpage", "nomatches")
    const txtFilterJob = getText("workflowpage", "filterjob")

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        "& > *": {
                            m: 1,
                        },
                    }}
                >

                    <ButtonGroup variant="outlined" size="small" color="primary" aria-label="outlined button group">
                        <Button startIcon={<Fa icon={faHeartCirclePlus} />} >{txtAll()}</Button>
                        <Button startIcon={<PlaylistAddIcon />}>{txtNoMatches()}</Button>
                        <Button startIcon={<PlaylistRemoveIcon />}>{txtMatches()}</Button>
                        <Button startIcon={<AddIcon />}>{txtFilterJob()}</Button>
                    </ButtonGroup>

                </Box>
            </Stack>

        </>
    )
}

export { MainLeftButtons, WFMatchingButtons }